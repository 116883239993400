<template>
	<div>
		<v-btn
			color="primary"
			class="my-2"
			outlined
			small
			:loading="loading"
			@click="openFileDialog"
		>
			<v-icon left>mdi-upload</v-icon>
			Cargar imagen
		</v-btn>
		<input
			style="display: none"
			ref="fileInput"
			accept=".jpg, .jpeg, .png"
			type="file"
			@change="onFileChange"
		/>
	</div>
</template>
<script>
//import { createAgreementAnnex } from '@/store/modules/agreement/service/agreement.service';
import { showSnack } from '@/helpers/globalHelpers';
export default {
	name: 'ImportImageButton',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		annex: {
			type: Object,
			required: false,
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		files: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		computedDateFormatted() {
			return this.formatDate(this.date);
		},
	},
	methods: {
		openFileDialog() {
			this.$refs.fileInput.click();
		},

		onFileChange(event) {
			const file = event?.target?.files[0];
			if (file) this.sendImage(file);
		},

		isImage(file) {
			if (file) {
				const allowedExtensions = ['jpg', 'jpeg', 'png'];
				const extension = file.name.split('.').pop().toLowerCase();
				if (!allowedExtensions.includes(extension)) {
					showSnack('Por favor, selecciona un archivo imagen', 'warning');
					return false;
				} else {
					return true;
				}
			}
			return false;
		},

		validateFileSize(file) {
			const maxSize = 1024 * 1024;
			if (file.size > maxSize) {
				showSnack(
					'El archivo es demasiado grande. El tamaño máximo permitido es 1MB.',
					'warning'
				);
				return false;
			}
			return true;
		},

		async sendImage(file) {
			try {
				if (this.isImage(file) && this.validateFileSize(file)) {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = (e) => {
						const base64 = e.target.result;
						const tag = `<img src="${base64}"/>`;
						this.$emit('onChange', tag);
					};
				}
			} catch (error) {
				showSnack(error?.toString(), 'error');
			} finally {
				this.$refs.fileInput.value = null;
			}
		},
	},
};
</script>
