import { formatDate, showSnack } from '@/helpers/globalHelpers';
import { listAgreementObservations } from '@/store/modules/agreement/service/agreement.service';

export default {
	name: 'ObservationList',
	props: {
		agreementId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			loadingObservations: false,
			observations: [],
		};
	},
	created() {
		this.getObservations();
	},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		formatDate,

		getObservations: async function () {
			try {
				this.loadingObservations = true;
				const response = await listAgreementObservations({
					agreement_id: this.agreementId,
				});
				this.observations = Array.isArray(response?.data) ? response?.data : [];
			} catch (error) {
				showSnack(error?.response?.data?.message || error.toString(), 'warning');
			} finally {
				this.loadingObservations = false;
			}
		},

		getUserObservations: function (observation) {
			return observation?.user_id?.fullName || '-';
		},
	},
	components: {},
};
