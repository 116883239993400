import { render, staticRenderFns } from "./ObservationBox.vue?vue&type=template&id=4f010bd6&scoped=true&"
import script from "./ObservationBox.js?vue&type=script&lang=js&"
export * from "./ObservationBox.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f010bd6",
  null
  
)

export default component.exports