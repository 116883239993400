import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import KeywordForm from '@/components/agreement/elements/KeywordForm/KeywordForm.vue';
//import { createAgreementType } from '@/store/modules/agreementType/service/agreementType.service';
import { showSnack } from '@/helpers/globalHelpers';
//import { alertUserNotification } from '@/helpers/globalHelpers';

export default {
	name: 'CreateAgreementTypeDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		keywordGroups: {
			type: Array,
			required: false,
		},
		keywords: {
			type: Array,
			required: false,
		},
		mode: {
			type: String,
			required: false,
		},
		keyword: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			valid: true,
			loadingSave: false,
			keywordForm: {
				label: '',
				key: '',
				group: '',
				group_key: '',
				note: null,
				input_type: '',
				values: null,
				columns: '12',
				required: true,
			},
			enableGroupForm: false,
			currentGroup: null,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				// (v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			inputTypes: [
				{ value: 'number', label: 'Numeral' },
				{ value: 'textarea', label: 'Área de texto' },
				{ value: 'date', label: 'Fecha' },
			],
		};
	},
	created() {},
	mounted() {},
	computed: {
		dialog: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		keyRule: function () {
			return [
				(v) => !!v || 'Campo requerido',
				(v) =>
					!this.isDuplicateKey(v) || 'No se puede registrar keyword repetidas',
			];
		},
		groupRule: function () {
			return [
				(v) => !!v || 'Campo requerido',
				(v) => !this.isDuplicateGroup(v) || 'No se puede registrar duplicados',
			];
		},
		groupKeyRule: function () {
			return [
				(v) => !!v || 'Campo requerido',
				(v) => !this.isDuplicateGroupKey(v) || 'No se puede registrar duplicados',
			];
		},
		groupList: function () {
			let groups = [];
			if (Array.isArray(this.keywords)) {
				this.keywords.forEach((keyword) => {
					const groupExists = groups.some(
						(e) =>
							e?.group == keyword?.group &&
							e?.group_key == keyword?.group_key
					);
					if (!groupExists) {
						groups.push({
							group: keyword?.group,
							group_key: keyword?.group_key,
						});
					}
				});
			}
			return groups;
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.enableGroupForm = false;
				if (this.mode === 'CREATE') {
					if (this.$refs?.keywordForm) this.$refs.keywordForm.reset();
				} else if (this.mode === 'UPDATE') {
					if (this.$refs?.keywordForm) this.$refs.keywordForm.resetValidation();
					this.setFormValues();
				}
			}
		},
	},
	methods: {
		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.resetValidation();
				}
			});
		},

		setFormValues: function () {
			this.keywordForm = { ...this.keyword };
			this.currentGroup = {
				group: this.keyword?.group,
				group_key: this.keyword?.group_key,
			};
		},

		getKey: function (value) {
			let key = '';
			if (value) {
				const tildes = {
					á: 'a',
					é: 'e',
					í: 'i',
					ó: 'o',
					ú: 'u',
					ü: 'u',
				};
				key = value.toLowerCase();
				key = key.replace(/[áéíóúüÁÉÍÓÚÜ]/g, function (match) {
					return tildes[match];
				});
				key = key.trim();
				key = key.replace(/\s+/g, '-');
				key = key.replace(/[{}]/g, '');
			}
			return key;
		},

		setKey: function (value) {
			this.keywordForm.key = this.getKey(value);
		},
		setGroupKey: function (value) {
			this.keywordForm.group_key = this.getKey(value);
		},

		saveKeyword: async function () {
			const isvalidated = this.$refs.keywordForm.validate();
			if (isvalidated) {
				this.$emit('addKeyword', { ...this.keywordForm });
				showSnack('La keyword fue agregada a la lista', 'success');
				this.cleanForm();
				this.dialog = false;
			}
		},

		updateKeyword: async function () {
			const isvalidated = this.$refs.keywordForm.validate();
			if (isvalidated) {
				this.$emit('editKeyword', this.keyword?.key, { ...this.keywordForm });
				showSnack('La keyword fue actualizada', 'success');
				this.cleanForm();
				this.dialog = false;
			}
		},

		isDuplicateKey: function (key) {
			if (this.mode === 'UPDATE' && this.keywordForm?.key == this.keyword?.key) {
				return false;
			} else {
				return (this.keywords || []).some((e) => e?.key == key);
			}
		},

		isDuplicateGroup: function (value) {
			if (Array.isArray(this.keywordGroups)) {
				return this.keywordGroups.some((e) => e?.group == value);
			}
		},

		isDuplicateGroupKey: function (value) {
			if (Array.isArray(this.keywordGroups)) {
				return this.keywordGroups.some((e) => e?.group_key == value);
			}
		},

		cancelGroup: function () {
			this.cleanGroupForm();
		},

		setMode: function (value) {
			this.mode = value;
		},

		cleanForm: function () {
			this.$nextTick(() => {
				this.keywordForm = {
					label: '',
					key: '',
					group: '',
					group_key: '',
					note: null,
					input_type: 'textarea',
					values: null,
					columns: '12',
					required: true,
				};
				if (this.$refs.keywordForm) this.$refs.keywordForm.reset();
			});
		},

		listenGroupSelect: function (item) {
			this.keywordForm.group = item?.group;
			this.keywordForm.group_key = item?.group_key;
		},

		openGroupForm: function () {
			this.enableGroupForm = true;
			this.keywordForm.group = '';
			this.keywordForm.group_key = '';
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		KeywordForm,
	},
};
