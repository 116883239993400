<template>
	<template-edit-page />
</template>
<script>
import TemplateEditPage from '@/components/agreement/agreementType/TemplateEditPage/TemplateEditPage.vue';

export default {
	name: 'AgreementTypeEditView',
	components: { TemplateEditPage },
};
</script>
