import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'CommentForm',
	props: {
		observation: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			valid: true,
			loading: false,
			loadingSave: false,
			comment: null,
			requiredRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		...mapActions('agreementObservation', ['createAgreementAnswer']),

		async sendApprove() {
			const isvalidated = this.$refs.commentForm.validate();
			if (isvalidated) {
				this.loadingSave = true;
				const data = {
					content: this.comment,
					description: '',
					agreement_notification_observation_id: this.observation?.id,
				};
				const { ok, response, error } = await this.createAgreementAnswer(data);
				if (ok) {
					this.$emit('send');
					this.cleanForm();
					this.showAlert(response.message, 'success');
				} else {
					this.showAlert(error?.data?.message);
				}
				this.loadingSave = false;
			}
		},

		showAlert(msmError, color = 'error') {
			//let msmError = error?.data?.message;
			msmError = Array.isArray(msmError) ? msmError.join('<br> ') : msmError;
			$EventBus.$emit('showSnack', color, msmError);
		},

		cleanForm() {
			this.$nextTick(() => {
				this.comment = null;
				if (this.$refs.commentForm) this.$refs.commentForm.reset();
			});
		},
	},
	components: {},
};
