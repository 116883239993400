import ValidationAlert from '@/components/global/ValidationAlert.vue';
import CDatePicker from '@/components/global/CDatePicker.vue';
import KeywordForm from '@/components/agreement/elements/KeywordForm/KeywordForm.vue';
//import { alertUserNotification } from '@/helpers/globalHelpers';

export default {
	name: 'UpdateAgreementTypeDialog',
	props: {
		value: {
			type: Boolean,
			required: false,
		},
		agreementTypeId: {
			type: [String, Number],
			required: false,
		},

		keywordGroups: {
			type: [Array],
			required: false,
		},
	},
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			userForm: {},
			userTable: [],
			users: [],
			requiredRule: [(v) => !!v || 'Campo requerido'],
			numberRules: [
				(v) => !!v || 'Campo requerido',
				(v) => /^\d*$/.test(v) || 'Campo numérico',
				// (v) => v > 0 || 'El valor debe ser mayor que cero',
			],
			headers: [
				{
					text: 'Usuario',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Email',
					value: 'user.email',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
			loadingUsers: false,
			loadingSave: false,
			loadingSearch: false,
			timeout: null,

			mode: 'LIST',
			groupForm: {
				group: '',
				group_key: '',
			},
			groups: [],
			keywords: [],
		};
	},
	created() {},
	mounted() {},
	computed: {
		myModel: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		groupsTree: function () {
			let groups = this.groups.map((group) => {
				return { ...group, items: [] };
			});
			(this.keywords || []).forEach((keyWord) => {
				const indexGroup = groups.findIndex(
					(group) => group?.idx == keyWord.groupIdx
				);
				if (indexGroup >= 0) {
					groups[indexGroup].items.push(keyWord);
				}
			});

			return groups;
		},
	},
	watch: {
		dialog(newVal) {
			if (newVal) {
				this.decomposeData(this.keywordGroups);
			}
			if (newVal && this.$refs.userForm) this.$refs.userForm.reset();
		},
	},
	methods: {
		generarID: function () {
			return 'xxxxxx'.replace(/x/g, function () {
				return Math.floor(Math.random() * 16).toString(16);
			});
		},
		decomposeData: function (items) {
			let groups = [];
			let keyWords = [];
			items.forEach((element) => {
				const isDuplicateGroup = groups.some(
					(e) => e?.group_key == element?.group_key
				);
				if (!isDuplicateGroup) {
					groups.push({
						group: element?.group,
						group_key: element?.group_key,
						idx: this.generarID(),
					});
				}
				const foundGroup = groups.find((e) => e?.group_key == element?.group_key);
				if (foundGroup) {
					keyWords.push({ ...element, groupIdx: foundGroup?.idx });
				}
			});
			this.groups = groups;
			this.keywords = keyWords;
		},
		closeDialog() {
			this.$nextTick(() => {
				if (this.$refs?.form) {
					this.$refs.form.resetValidation();
				}
			});
		},

		// async getUsers(params) {
		// 	this.loading = true;
		// 	const { ok, response, error } = await this.searchUsers(params);
		// 	if (ok) {
		// 		this.users = Array.isArray(response) ? response : [];
		// 		//alertUserNotification(response);
		// 	} else {
		// 		this.showAlert(error?.data?.message || 'Error');
		// 	}
		// 	this.loading = false;
		// },

		// async removeUser(item) {
		// 	const { isConfirmed } = await this.$swal.fire({
		// 		title: '¿Estás seguro de que quieres eliminar?',
		// 		text: '',
		// 		icon: 'question',
		// 		showCancelButton: true,
		// 		cancelButtonColor: '#e0e0e0',
		// 		confirmButtonColor: '#0039a6',
		// 		cancelButtonText: 'No',
		// 		confirmButtonText: 'Sí',
		// 		reverseButtons: true,
		// 	});
		// 	if (isConfirmed) {
		// 		const { ok, response, error } = await this.deleteNotificationUser({
		// 			agreementId: item?.id,
		// 		});
		// 		if (ok) {
		// 			this.getNotificationUsers();
		// 			this.showAlert(response?.message || 'Eliminado', 'success');
		// 		} else {
		// 			this.showAlert(error?.data?.message || 'Error');
		// 		}
		// 	}
		// 	this.cleanForm();
		// },

		setGroupKey(value) {
			let key = '';
			if (value) {
				const tildes = {
					á: 'a',
					é: 'e',
					í: 'i',
					ó: 'o',
					ú: 'u',
					ü: 'u',
				};
				key = value.toLowerCase();
				key = key.replace(/[áéíóúüÁÉÍÓÚÜ]/g, function (match) {
					return tildes[match];
				});
				key = key.trim();
				key = key.replace(/\s+/g, '-');
				key = key.replace(/[{}]/g, '');
			}
			this.groupForm.group_key = key;
		},

		saveGroup: function () {
			const isvalidated = this.$refs.groupForm.validate();
			if (isvalidated) {
				this.groups.push({ ...this.groupForm, idx: this.generarID() });
				this.cleanGroupForm();
				this.mode = 'LIST';
			}
		},

		cancelGroup: function () {
			this.cleanGroupForm();
			this.mode = 'LIST';
		},

		setMode: function (value) {
			this.mode = value;
		},

		cleanGroupForm: function () {
			this.$nextTick(() => {
				this.groupForm.group = null;
				this.groupForm.group_key = null;
				if (this.$refs.groupForm) this.$refs.groupForm.reset();
			});
		},
	},
	components: {
		ValidationAlert,
		CDatePicker,
		KeywordForm,
	},
};
