var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"agreementEditor"}},[(_vm.loadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pl-1 pl-sm-8",attrs:{"col":"12","md":"8"}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-row',{staticClass:"mr-auto mb-4",attrs:{"justify":"space-between","align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.agreementType)?_c('div',{staticClass:"text-h5 primary--text",domProps:{"textContent":_vm._s(_vm.agreementType?.name)}}):_vm._e()]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":"primary","outlined":""},on:{"click":() => _vm.btnCancel()}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":"primary","loading":_vm.loadingSave},on:{"click":() => _vm.saveAgreement()}},[_vm._v(" Guardar ")])],1)],1),(_vm.msgError && _vm.msgError.length)?_c('div',_vm._l((_vm.msgError),function(error,index){return _c('v-alert',{key:index,attrs:{"color":"red","type":"error","dense":""}},[_vm._v(" "+_vm._s(error)+" ")])}),1):_vm._e(),(_vm.editor)?_c('div',{staticClass:"editor-wrapper"},[_c('import-image-button',{on:{"onChange":_vm.onChangeImage}}),_c('div',{staticClass:"white",on:{"dragover":function($event){$event.preventDefault();}}},[_c('ckeditor',{attrs:{"editor":_vm.editor,"config":_vm.editorConfig},on:{"ready":_vm.onReady},model:{value:(_vm.editorData),callback:function ($$v) {_vm.editorData=$$v},expression:"editorData"}})],1)],1):_vm._e()],1),(_vm.keywordList)?_c('v-col',{attrs:{"col":"12","md":"4"}},[_c('div',{ref:"wrapperFloating",style:({
						position: 'relative',
						width: '100%',
						minHeight: '100%',
					}),attrs:{"id":"wrapper-floating"}},[_c('div',{staticClass:"floating pa-2 pr-8",style:({
							width: '100%',
							'max-width': _vm.widthFloating,
						})},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between align-center",style:({ width: '100%' })},[_c('span',[_vm._v("Lista de keywords")]),_c('v-btn',{staticClass:"text-none my-2",attrs:{"color":"primary","outlined":"","small":"","text":""},on:{"click":function($event){return _vm.openCreateDialog()}}},[_vm._v(" Agregar keywords ")])],1)]),_c('v-card-text',_vm._l((_vm.keywordList),function(item,index){return _c('div',{key:index,staticClass:"pa-2"},[_c('div',{domProps:{"textContent":_vm._s(item?.label)}}),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-btn',{staticClass:"my-button justify-center align-center bold",attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.key)},"dragstart":function($event){return _vm.dragStart(item.key)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-chip',{staticClass:"chip justify-center align-center",attrs:{"draggable":true,"small":""},on:{"dragstart":(e) => _vm.dragStart(e, item.key)}},[_vm._v(" "+_vm._s(item.key)+" ")])],1),_c('div',[_c('v-btn',{staticClass:"ml-1",attrs:{"title":"Editar","color":"primary","icon":"","small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"title":"Eliminar","color":"grey","icon":"","small":""},on:{"click":function($event){return _vm.deleteKeyword(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can ")])],1)],1)])])}),0)],1)],1)])]):_vm._e()],1)],1),_c('create-agreement-type-dialog',{attrs:{"keywordGroups":_vm.agreementType?.fields,"keywords":_vm.keywordList,"keyword":_vm.currentKeyword,"mode":_vm.modeDialog},on:{"addKeyword":_vm.addKeyword,"editKeyword":_vm.editKeyword,"update":_vm.readAgreement},model:{value:(_vm.isEnableKeywordDialog),callback:function ($$v) {_vm.isEnableKeywordDialog=$$v},expression:"isEnableKeywordDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }