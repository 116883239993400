import { mapState, mapActions } from 'vuex';
import CommentForm from '@/components/contract/agreement/createComment/CommentForm.vue';
import { formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'CommentBox',
	props: {
		agreement: {
			type: [Object],
			required: true,
		},
	},
	data() {
		return {};
	},
	created() {
		if (this.agreement?.lastNotification?.id) {
			this.listAgreementObservation({
				agreement_id: this.agreement?.id,
				pagination: true,
				per_page: 5,
			});
		}
	},
	mounted() {},
	computed: {
		...mapState('agreementObservation', ['agreementObservations']),
	},
	watch: {},
	methods: {
		...mapActions('agreementObservation', ['listAgreementObservation']),

		formatDate,

		async getObservations() {
			await this.listAgreementObservation({
				agreement_id: this.agreement?.id,
				pagination: true,
				per_page: 5,
			});
		},

		cutText(texto = '', longitudMaxima = 15) {
			if (texto && texto.length > longitudMaxima) {
				return texto.slice(0, longitudMaxima) + '...';
			} else {
				return texto;
			}
		},
	},
	components: {
		CommentForm,
	},
};
