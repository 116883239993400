import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import { showSnack } from '@/helpers/globalHelpers';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es.js';
import ImportImageButton from '@/components/agreement/ImportImageButton/ImportImageButton.vue';
import ObservationBox from '@/components/agreement/elements/ObservationBox/ObservationBox.vue';
import ObservationList from '@/components/agreement/elements/ObservationList/ObservationList.vue';
import UpdateAgreementTypeDialog from '@/components/agreement/elements/UpdateAgreementTypeDialog/UpdateAgreementTypeDialog.vue';
import CreateAgreementTypeDialog from '@/components/agreement/elements/CreateAgreementTypeDialog/CreateAgreementTypeDialog.vue';
import {
	deleteField,
	getTemplateByAgreementType,
	updateAgreementTypes,
} from '@/store/modules/agreementType/service/agreementType.service';

export default {
	name: 'TemplateEditPage',
	props: {},
	data: () => ({
		editor: DecoupledDocument,
		editorData: '',
		editorConfig: {
			language: 'es',
			toolbar: {
				removeItems: ['uploadImage', 'mediaEmbed'],
			},
		},

		loadingData: false,
		loadingSave: false,
		loadingDelete: false,
		agreementType: {
			name: '',
		},
		msgError: [],
		widthFloating: '',
		keywordList: [],
		isEnableKeywordDialog: false,
		modeDialog: 'CREATE',
		currentKeyword: null,
	}),
	computed: {
		...mapState('agreement', ['agreement', 'getTemplate']),
		agreementId() {
			return this.$route.params?.agreementTypeId || null;
		},

		agreementTypeId: function () {
			return this.$route.params?.agreementTypeId || null;
		},

		breadcrumbs() {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: 'Tipos de documento',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AgreementTypeList',
					},
				},
				// {
				// 	text: this.agreement?.name || 'Precontrato',
				// 	link: true,
				// 	exact: true,
				// 	disabled: false,
				// 	to: {
				// 		name: 'ReadAgreement',
				// 		params: {
				// 			agreementId: this.$route.params?.agreementId,
				// 		},
				// 	},
				// },
				{
					text: 'Plantilla',
					disabled: true,
					href: '/',
				},
			];
		},
		disablebtn() {
			return true;
		},
	},
	watch: {},
	created() {
		this.readAgreement();
		this.listenResize();
	},
	methods: {
		...mapActions('agreement', [
			'getAgreement',
			'cleanAgreement',
			'createAgreementWord',
		]),

		listenResize() {
			const that = this;
			window.addEventListener(
				'resize',
				function () {
					that.setWidthFloating();
				},
				true
			);
		},

		setWidthFloating() {
			this.$nextTick(() => {
				let wrapper = this.$refs?.wrapperFloating;
				if (wrapper) {
					this.widthFloating = wrapper?.offsetWidth + 'px';
				}
			});
		},

		readAgreement: async function () {
			if (this.agreementTypeId) {
				try {
					this.loadingData = true;
					const response = await getTemplateByAgreementType(
						this.agreementTypeId
					);
					this.editorData = response?.data?.document_format;
					this.keywordList = this.getKeywordList(response?.data?.fields);
					this.agreementType = response?.data;
					this.setWidthFloating();
				} catch (error) {
					showSnack(error.toString(), 'error');
				} finally {
					this.loadingData = false;
				}
			}
		},

		getKeywordList: function (fields) {
			let result = [];
			if (Array.isArray(fields)) {
				fields.forEach((group) => {
					group?.items.forEach((item) => {
						result.push(item);
					});
				});
			}
			return result;
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},

		saveAgreement: async function () {
			try {
				this.loadingSave = true;
				this.msgError = [];
				const data = {
					name: this.agreementType.name,
					document_format: this.editorData,
					keywords: this.keywordList,
				};
				await updateAgreementTypes(this.agreementTypeId, data);
				showSnack('La operación se completó exitosamente', 'success');
				this.readAgreement();
			} catch (error) {
				let msm = error?.response?.data?.message;
				showSnack(msm || error.toString(), 'error');
				this.msgError = Array.isArray(msm) ? msm : [msm];
			} finally {
				this.loadingSave = false;
			}
		},

		addKeyword: function (keyWord) {
			this.keywordList.push(keyWord);
		},

		async copyToClipboard(keyword) {
			try {
				await navigator.clipboard.writeText('{{' + keyword + '}}');
				setTimeout(() => {
					this.showAlert('success', 'Keyword Copiada');
				}, 100);
			} catch (error) {
				this.showAlert('success', 'Error al copiar la Keyword');
			}
		},

		dragStart(event, key) {
			event.dataTransfer.setData('text/plain', '{{' + key + '}}');
		},

		async btnCancel() {
			this.$router.push({
				name: 'AgreementTypeList',
			});
		},

		editKeyword: function (beforeKey, keyword) {
			const index = this.keywordList.findIndex((e) => e?.key === beforeKey);
			if (index >= 0) {
				this.keywordList[index] = { ...keyword };
			}
		},

		deleteKeyword: async function (keyword) {
			const { isConfirmed } = await this.$swal.fire({
				title: '¿Estás seguro de que quieres eliminar?',
				text: '',
				icon: 'question',
				showCancelButton: true,
				cancelButtonColor: '#e0e0e0',
				confirmButtonColor: '#0039a6',
				cancelButtonText: 'No',
				confirmButtonText: 'Sí',
				reverseButtons: true,
			});
			if (isConfirmed) {
				if (keyword?.id) {
					try {
						this.loadingDelete = true;
						await deleteField(keyword?.id);
						showSnack('La operación se ha ejecutado exitosamente', 'success');
						this.readAgreement();
					} catch (error) {
						showSnack(
							error?.response?.data?.message || error.toString(),
							'error'
						);
					} finally {
						this.loadingDelete = false;
					}
				} else {
					const index = this.keywordList.findIndex(
						(e) => e?.key === keyword?.key
					);
					this.keywordList.splice(index, 1);
				}
			}
		},

		openEditDialog: function (keyword) {
			this.modeDialog = 'UPDATE';
			this.currentKeyword = keyword;
			this.isEnableKeywordDialog = true;
		},

		openCreateDialog: function () {
			this.modeDialog = 'CREATE';
			this.currentKeyword = {};
			this.isEnableKeywordDialog = true;
		},

		onChangeImage: function (tagImage) {
			this.editorData = tagImage + this.editorData;
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
	},
	components: {
		ObservationBox,
		ObservationList,
		UpdateAgreementTypeDialog,
		CreateAgreementTypeDialog,
		ImportImageButton,
	},
};
