import ButtonFile from '@/components/global/ButtonFile.vue';

export default {
	name: 'KeywordForm',
	props: {
		value: {
			type: [Array],
			required: false,
		},
		agreementTypeId: {
			type: [Number, String],
			required: false,
		},
	},
	data() {
		return {
			valid: null,
			loading: false,
			requiredRule: [(v) => !!v || 'Campo requerido'],
			annexForm: {
				name: null,
				flag_mandatory: true,
				flag_required_for_provider: false,
				default: false,
			},
			keywordForm: {
				label: 'Responsable de Contrato',
				key: 'responsable-de-contrato',
				group: 'Cabecera',
				group_key: 'cabecera',
				note: '',
				input_type: 'textarea',
				values: null,
				columns: '12',
				required: true,
			},
		};
	},
	created() {
		//this.getAnnexesByType();
	},
	mounted() {},
	computed: {
		annexes: {
			get() {
				return this.value || false;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	watch: {},
	methods: {
		saveKeyWord: function () {},
		cancel: function () {
			this.$emit('setMode', 'LIST');
		},
		setGroupKey(value) {
			let key = '';
			if (value) {
				const tildes = {
					á: 'a',
					é: 'e',
					í: 'i',
					ó: 'o',
					ú: 'u',
					ü: 'u',
				};
				key = value.toLowerCase();
				key = key.replace(/[áéíóúüÁÉÍÓÚÜ]/g, function (match) {
					return tildes[match];
				});
				key = key.trim();
				key = key.replace(/\s+/g, '-');
				key = key.replace(/[{}]/g, '');
			}
			this.keywordForm.key = key;
		},

		// addAnnex() {
		// 	this.annexes.push({ ...this.annexForm });
		// 	this.$nextTick(() => {
		// 		this.annexForm.name = null;
		// 	});
		// },

		// removeAnnex(index) {
		// 	this.annexes.splice(index, 1);
		// },

		// formattingAnnexes(annexes) {
		// 	if (Array.isArray(annexes)) {
		// 		return annexes.map((annex) => {
		// 			return {
		// 				default: true,
		// 				name: annex?.name,
		// 				flag_required_for_provider: false,
		// 				flag_mandatory: annex?.flag_mandatory,
		// 			};
		// 		});
		// 	} else {
		// 		return annexes;
		// 	}
		// },

		// async getAnnexesByType() {
		// 	try {
		// 		const queryParams = {
		// 			agreement_type_id: this.agreementTypeId,
		// 		};
		// 		const response = await listAnnexesByType(queryParams);
		// 		const annexes = response?.data || [];
		// 		this.annexes = this.formattingAnnexes(annexes);
		// 	} catch (error) {
		// 		this.showSnack(error?.response?.data?.message, 'error');
		// 	}
		// },

		// showSnack(message, color = 'success') {
		// 	message = Array.isArray(message) ? message.join('<br> ') : message;
		// 	$EventBus.$emit('showSnack', color, message);
		// },
	},
	beforeDestroy() {},
	components: {
		ButtonFile,
	},
};
